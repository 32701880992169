
import { defineComponent } from 'vue'

// 注意！這是一個 Recursive Component
export default defineComponent({
  props: {
    tree: Object
  },
  setup() {
    return {}
  }
})
