<template>
  <li :data-value="tree.value">
    {{ tree.name }}
    <ul v-if="tree.node && tree.node.length > 0">
      <ContextMenuItem
        v-for="(node, index) in tree.node"
        :key="index"
        :tree="node"
      />
    </ul>
  </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// 注意！這是一個 Recursive Component
export default defineComponent({
  props: {
    tree: Object
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
