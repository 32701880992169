
import { defineComponent, ref } from 'vue'
import '@progress/kendo-ui/js/kendo.menu'
import { ContextMenu } from '@progress/kendo-layout-vue-wrapper'
import ContextMenuItem from '@/components/ContextMenu/ContextMenuItem.vue'

export default defineComponent({
  props: {
    iconName: String,
    text: String,
    id: String,
    menuData: Array
  },
  components: {
    ContextMenu,
    ContextMenuItem
  },
  setup() {
    const isDropDownOpen = ref(false)

    const open = e => {
      if (e.item.length) {
        if (isDropDownOpen.value) {
          e.preventDefault() // Menu 已經開啟了，手動取消 open 事件
        }
        isDropDownOpen.value = !isDropDownOpen.value
      }
    }
    const close = () => {
      setTimeout(() => {
        isDropDownOpen.value = false
      }, 100)
    }

    return {
      open,
      close
    }
  }
})
