<template>
  <div>
    <div class="control-btn control-btn-with-dropdown" :id="id">
      <SvgIconFixedColor :icon="iconName" class="main-icon" />
      {{ text }}
      <SvgIcon icon="arrow-down" />
    </div>
    <ContextMenu
      :target="`#${id}`"
      :show-on="'click'"
      :align-to-anchor="true"
      :close-on-click="true"
      @open="open"
      @close="close"
      @select="$emit('select', $event.item.getAttribute('data-value'))"
    >
      <ContextMenuItem
        v-for="(tree, index) in menuData"
        :key="index"
        :tree="tree"
      />
    </ContextMenu>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import '@progress/kendo-ui/js/kendo.menu'
import { ContextMenu } from '@progress/kendo-layout-vue-wrapper'
import ContextMenuItem from '@/components/ContextMenu/ContextMenuItem.vue'

export default defineComponent({
  props: {
    iconName: String,
    text: String,
    id: String,
    menuData: Array
  },
  components: {
    ContextMenu,
    ContextMenuItem
  },
  setup() {
    const isDropDownOpen = ref(false)

    const open = e => {
      if (e.item.length) {
        if (isDropDownOpen.value) {
          e.preventDefault() // Menu 已經開啟了，手動取消 open 事件
        }
        isDropDownOpen.value = !isDropDownOpen.value
      }
    }
    const close = () => {
      setTimeout(() => {
        isDropDownOpen.value = false
      }, 100)
    }

    return {
      open,
      close
    }
  }
})
</script>

<style scoped></style>
